import React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Query } from "react-apollo";
import CategoryAccordion from "./components/CategoryAccordion";
import { CATEGORIES, CATEGORIES_FILTER_ID } from "./constants";

const CategoryFilter = ({ filters, handleCategoriesSelected }) => {
  const { locale } = useIntl();

  return (
    <>
      <div className="custom_calss_main_container_filter">
        <h5 className="font-weight-bold category-filter__filter-by ">
          <FormattedMessage
            id="Projects.Filter.title"
            defaultMessage="Filter by:"
          />
        </h5>
        {CATEGORIES.map((category) =>
          !category?.optionsQuery ? (
            <CategoryAccordion
              key={`key${category.id}`}
              filters={filters}
              handleCategoriesSelected={handleCategoriesSelected}
              {...category}
            />
          ) : (
            <Query
              key={`key${category.id}`}
              query={category.optionsQuery}
              variables={
                category?.id === CATEGORIES_FILTER_ID.marketSegments
                  ? {
                      lang: locale,
                    }
                  : {}
              }
            >
              {({ loading, error, data }) => {
                if (loading || error) return null;

                let resultData = { ...category, options: data[category.id] };

                if (category?.id === CATEGORIES_FILTER_ID.marketSegments) {
                  const formattedData = data?.projectSegments?.map(
                    ({ title, value }) => ({
                      name: title,
                      id: value,
                    })
                  );
                  resultData = { ...resultData, options: formattedData };
                }

                if (category?.id === CATEGORIES_FILTER_ID.cities) {
                  const formattedData = data?.citiesAvailableForProjectSearch?.map(
                    (value) => ({
                      name: value,
                      id: value,
                    })
                  );
                  resultData = { ...resultData, options: formattedData };
                }

                return (
                  <CategoryAccordion
                    filters={filters}
                    handleCategoriesSelected={handleCategoriesSelected}
                    {...resultData}
                  />
                );
              }}
            </Query>
          )
        )}
      </div>
    </>
  );
};

export default CategoryFilter;
