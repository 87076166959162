import gql from "graphql-tag";

export const projectQuery = gql`
  query getPromotedProject($slug: String) {
    promotedProject(slug: $slug) {
      id
      slug
      title
      year
      image
      images
      specifiedBy
      manufacturer
      marketSegment
      applicationEn
      applicationEs
      instances {
        edges {
          node {
            id
            name
          }
        }
      }
      zippedImages
      products {
        edges {
          node {
            id
            name
            slug
          }
        }
      }

      country {
        id
        name
      }

      city {
        id
        name
      }
      relatedProjects {
        edges {
          node {
            id
            slug
            image
            title
            city {
              id
              name
            }
            products {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
      authorization
    }
  }
`;
