import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { FormattedMessage } from "react-intl";
import "./styles/ProductSpecs.scss";
import {
  singlePatternBackingQuery,
  singlePatternPdfFlameRetardancyQuery,
  singlePatternGeneralQuery,
  singlePatternOtherAttributesQuery,
  singlePatternProductCategorizationQuery,
  singlePatternSustainabilityAttributes,
} from "scenes/ProductDetails/queries";
import AdditionalTestAccordion from "scenes/ProductDetails/components/ProductSpecs/components/AdditionalTestAccordion";
import SpecsTableRow from "scenes/ProductDetails/components/ProductSpecs/components/SpecsTableRow";
import SpecsTableAccordion from "utils/components/SpecsTableAccordion";
import LoadingMessage from "utils/components/LoadingMessage";
import { Query } from "react-apollo";
import ChangeUnitSystemLink from "scenes/ProductDetails/components/ProductSpecs/components/ChangeUnitSystemLink";
import { processDataTable } from "./utils";
import FlameRetardancyAccordion from "scenes/ProductDetails/components/ProductSpecs/components/FlameRetardancyAccordion";

import GeneralAccordion from "./components/GeneralAccordion";
import OtherAttributesAccordion from "./components/OtherAttributesAccordion";
import SustainabilityAttributesAccordion from "./components/SustainabilityAttributesAccordion";
import { checkEmptyData } from "./contants/checkEmptyData";

const tables = [
  {
    title: (
      <FormattedMessage
        id="ProductSpecs.productCategorization"
        description="Product Categorization table title at product specs section"
        defaultMessage="Product Categorization"
      />
    ),
    query: singlePatternProductCategorizationQuery,
    lookup: "productcategorization",
    fetchPolicy: "cache-first",
  },
  {
    title: (
      <FormattedMessage
        id="ProductSpecs.productSustainabilityTitle"
        description="Product Sustainability table title at product specs section"
        defaultMessage="Sustainability Attributes"
      />
    ),
    query: singlePatternSustainabilityAttributes,
    lookup: "sustainability",
    fetchPolicy: "cache-first",
  },
  {
    title: (
      <React.Fragment>
        <FormattedMessage
          id="ProductSpecs.generalTableTitle"
          description="General table title at product specs section"
          defaultMessage="General"
        />
        <ChangeUnitSystemLink />
      </React.Fragment>
    ),
    query: singlePatternGeneralQuery,
    lookup: "general",
    fetchPolicy: "cache-first",
  },
  {
    title: (
      <FormattedMessage
        id="ProductSpecs.backingTableTitle"
        description="Backing table title at product specs section"
        defaultMessage="Backing"
      />
    ),
    query: singlePatternBackingQuery,
    lookup: "backing",
    fetchPolicy: "cache-first",
  },
  {
    title: (
      <FormattedMessage
        id="ProductSpecs.otherAttributesTableTitle"
        description="Other Attributes table title at product specs section"
        defaultMessage="Other Attributes"
      />
    ),
    query: singlePatternOtherAttributesQuery,
    lookup: "otherattributes",
    fetchPolicy: "cache-first",
  },
  {
    title: (
      <FormattedMessage
        id="ProductSpecs.flameRetardancyTableTitle"
        description="Flame Retardancy table title at product specs section"
        defaultMessage="Flame Retardancy"
      />
    ),
    query: singlePatternPdfFlameRetardancyQuery,
    lookup: "successPdfFlameRetardancyTests",
    fetchPolicy: "cache-and-network",
  },
];

export default function ProductSpecs({ patternId }) {
  const [activeAccordions, setActiveAccordions] = useState([0]);
  /**
   * Handles clicks on the close all button to close all the tables
   * @param {Object} event
   */
  function handleCloseAllButtonClick(event) {
    setActiveAccordions([]);
  }

  /**
   * Handles clicks on the ProductSpecsAccordion's toggle button to change visibility of the table
   * @param {Number} index
   */
  function handleToggleClick(index) {
    if (activeAccordions.indexOf(index) > -1)
      setActiveAccordions(_.without(activeAccordions, index));
    else setActiveAccordions([...activeAccordions, index]);
  }

  return (
    <div className="product-specs">
      <h1 className="product-specs__title">
        <FormattedMessage
          id="ProductSpecs.title"
          description="Product specs section title"
          defaultMessage="Specs"
        />
        <button type="button" onClick={handleCloseAllButtonClick}>
          <i className="sp-minus" />
          <FormattedMessage
            id="ProductSpecs.closeAllButtonLabel"
            description="Label for close all button at product specs section title"
            defaultMessage="Close all"
          />
        </button>
      </h1>
      <div className="product-specs__tables">
        {tables.map((table, index) => {
          const isOpen = activeAccordions.indexOf(index) > -1;

          return (
            <div key={index} className="mb-3">
              <Query
                query={table.query}
                variables={{ id: patternId, }}
                fetchPolicy={table.fetchPolicy}
              >
                {({ loading, error, data }) => {
                  const pattern = _.get(data, "pattern");

                  const unprocessedTableData = pattern
                    ? _.get(pattern, table.lookup, null)
                    : null;

                  const tableData = processDataTable(unprocessedTableData, table.lookup);
                  const reesultTableData = checkEmptyData(tableData, table.lookup)

                  // Management the error if error exists - create a new attr in tables to know if have error
                  if (error) {
                    tables.map((accordionTable) => {
                      if (accordionTable.title === table.title) {
                        accordionTable.haveErrorInQuery = true
                      }
                    })
                  }

                  // if error not exists and the tableData is null return null to not render accordion
                  if (reesultTableData && !table.haveErrorInQuery) {
                    return null
                  }

                  return (
                    <SpecsTableAccordion
                      onClickToggle={() => handleToggleClick(index)}
                      isOpen={isOpen}
                      title={table.title}
                    >
                      <table className="table table-striped product-specs-table">
                        <tbody>
                          {loading && _.isEmpty(data) && (
                            <tr>
                              <td colSpan={2}>
                                <LoadingMessage />
                              </td>
                            </tr>
                          )}

                          {table.haveErrorInQuery && <tr>
                              <td colSpan={2}>
                                <FormattedMessage
                                  id="ProductSpecs.errorMessage"
                                  description="Error message on product specs page"
                                  defaultMessage="An error has occurred while loading data, please try again and/or verify that the record exists."
                                />
                              </td>
                            </tr>}

                          {!loading && !reesultTableData && tableData !== null && (
                            <>
                              {(() => {
                                if (["sustainability"].indexOf(table.lookup) > -1) {
                                  return <SustainabilityAttributesAccordion data={pattern} patternId={pattern?.id} />
                                }

                                if (["general"].indexOf(table.lookup) > -1) {
                                  return <GeneralAccordion data={tableData} />;
                                }

                                if (["otherattributes"].indexOf(table.lookup) > -1) {
                                  return <OtherAttributesAccordion data={tableData} />
                                }

                                if (["successPdfFlameRetardancyTests"].indexOf(table.lookup) > -1) {
                                  return <FlameRetardancyAccordion data={tableData} />
                                }

                                return <SpecsTableRow data={tableData} />;
                              })()}
                            </>
                          )}
                        </tbody>
                      </table>
                    </SpecsTableAccordion>
                  );
                }}
              </Query>
            </div>
          );
        })}
        <AdditionalTestAccordion
          title={
            <FormattedMessage
              id="ProductSpecs.additionalTests"
              description="Additional Tests table title at product specs section"
              defaultMessage="Additional Tests"
            />
          }
          isOpen={activeAccordions.indexOf(tables.length) > -1}
          onClickToggle={() => handleToggleClick(tables.length)}
        />
      </div>
    </div>
  );
}

ProductSpecs.propTypes = {
  patternId: PropTypes.string.isRequired,
};
