import { useEffect, useState } from 'react'
import { INSTANCES_TO_SKIP_AND_NOT_SHOW } from '../constants';
import { CATEGORIES } from '../../../constants';
import { handleMixInstancesAccordingCode } from '../constants/handleMixInstancesByRegion';
import { OPTIONS_LABEL_FOR_INSTANCES } from 'scenes/SearchProjects/components/ProjectsByCategory/constants/categoriesOptionsToSearch';
import { useIntl } from "react-intl";

export const useFilterOptions = (options, name) => {
  const { locale } = useIntl();
  const [filterOptions, setFilterOptions] = useState(options);

  const ID_TO_NAME_INSTANCE = {
    "SW5zdGFuY2VOb2RlOjQ=": locale === 'es' ? 'Europa' : 'Europe',
    "SW5zdGFuY2VOb2RlOjE=": locale === 'es' ? 'Latinoamérica' : 'Latin America',
    "SW5zdGFuY2VOb2RlOjM=": locale === 'es' ? 'Estados Unidos' : 'United States',
  };

  const ID_TO_NAME_COUNTRIES = {
    "Q291bnRyeU5vZGU6MTA=": locale === 'es' ? 'Argentina' : 'Argentina',
    "Q291bnRyeU5vZGU6MzE=": locale === 'es' ? 'Brasil' : 'Brazil',
    "Q291bnRyeU5vZGU6NDk=": locale === 'es' ? 'Colombia' : 'Colombia',
    "Q291bnRyeU5vZGU6NjM=": locale === 'es' ? 'Ecuador' : 'Ecuador',
    "Q291bnRyeU5vZGU6NzU=": locale === 'es' ? 'Francia' : 'France',
    "Q291bnRyeU5vZGU6NTc=": locale === 'es' ? 'Alemania' : 'Germany',
    "Q291bnRyeU5vZGU6ODk=": locale === 'es' ? 'Grecia' : 'Greece',
    "Q291bnRyeU5vZGU6MTM0": locale === 'es' ? 'Lituania' : 'Lithuania',
    "Q291bnRyeU5vZGU6MTU4": locale === 'es' ? 'México' : 'Mexico',
    "Q291bnRyeU5vZGU6MTY3": locale === 'es' ? 'Países Bajos' : 'Netherlands',
    "Q291bnRyeU5vZGU6MTg3": locale === 'es' ? 'Paraguay' : 'Paraguay',
    "Q291bnRyeU5vZGU6MTc1": locale === 'es' ? 'Perú' : 'Peru',
    "Q291bnRyeU5vZGU6Njg=": locale === 'es' ? 'España' : 'Spain',
    "Q291bnRyeU5vZGU6MjI2": locale === 'es' ? 'Turquía' : 'Turkey',
    "Q291bnRyeU5vZGU6Mg==": locale === 'es' ? 'Emiratos Árabes Unidos' : 'United Arab Emirates',
    "Q291bnRyeU5vZGU6Nzc=": locale === 'es' ? 'Reino Unido' : 'United Kingdom',
    "Q291bnRyeU5vZGU6MjM0": locale === 'es' ? 'Estados Unidos' : 'United States',
  };
  
	// NOTE - Return the result instances ids mix according the current option clicked by user
	const handleMixInstances = (currentOption) => {
		const resultMixUsaInstances = handleMixInstancesAccordingCode(options, currentOption, OPTIONS_LABEL_FOR_INSTANCES[1].key);
		const resultMixLatamInstances = handleMixInstancesAccordingCode(options, currentOption, OPTIONS_LABEL_FOR_INSTANCES[0].key);

		const resultInstancesGroup = 
    currentOption.code === OPTIONS_LABEL_FOR_INSTANCES[0].key ? resultMixLatamInstances : resultMixUsaInstances
    
		return resultInstancesGroup;
	}

	// NOTE - filter options to not show marine, resources and mexico instances in the business filter
  //Filter Instances
	useEffect(() => {
    if (name === CATEGORIES[1].name) {
      const filteredOptions = options?.filter((option) => {
        return !INSTANCES_TO_SKIP_AND_NOT_SHOW.some((instance) => {
          return option.code === instance;
        })
      })

      const newObjectInstances = filteredOptions?.map((item) => {
        return {
          ...item,
          name: ID_TO_NAME_INSTANCE[item?.id] || item.name,
        };
      });

      setFilterOptions(newObjectInstances);
    }
  }, [])

  //Filter Countries
  useEffect(() => {
    if (name === 'countries') {
      const filteredOptionsCountries = options?.filter((option) => {
        return !INSTANCES_TO_SKIP_AND_NOT_SHOW.some((instance) => {
          return option.code === instance;
        })
      })

      const newObjectCountries = filteredOptionsCountries?.map((item) => {
        return {
          ...item,
          name: ID_TO_NAME_COUNTRIES[item?.id] || item.name,
        };
      });
      setFilterOptions(newObjectCountries);
    }
  }, [])

  return { filterOptions, handleMixInstances }
}
