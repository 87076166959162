import React from "react";
import "./styles/MainMenuSections.scss";
import MainMenuDropdown from "components/Header/components/HeaderMainMenu/components/MainMenuSections/components/MainMenuDropdown";
import { FormattedMessage } from "react-intl";
import { MARKET_SEGMENTS } from "scenes/ProductsPage/components/FiltersBar/contants/market_segments";
import { QUERY_PARAM_SEGMENT } from "scenes/ProductsPage/components/FiltersBar/contants/query_params";
import proquinalHistory from "services/browser-history";

const {
  location: { pathname },
} = proquinalHistory;

const MENU_GENERAL = [
  {
    isMenu: false,
    title: (
      <FormattedMessage
        id="MainMenuSections.hardCodedLink.product"
        description="Products Library hard-coded link in the header nav buttons"
        defaultMessage="Product Library"
      />
    ),
    path: "/products",
  },
  {
    isMenu: false,
    title: (
      <FormattedMessage
        id="MainMenuSections.hardCodedLink.project"
        description="Products Library hard-coded link in the header nav buttons"
        defaultMessage="Project Library"
      />
    ),
    path: "/projects",
  },
];

const MENU_LIST = {
  "/products": [
    {
      isMenu: false,
      title: (
        <FormattedMessage
          id="MainMenuSections.hardCodedLink.product"
          description="Products Library hard-coded link in the header nav buttons"
          defaultMessage="Product Library"
        />
      ),
      path: "/products",
    },
    {
      isMenu: false,
      title: (
        <FormattedMessage
          id="MainMenuSections.hardCodedLink.project"
          description="Products Library hard-coded link in the header nav buttons"
          defaultMessage="Project Library"
        />
      ),
      path: "/projects",
    },
  ],
};

const MainMenuSections = () => {
  const menu = MENU_LIST[pathname] || MENU_GENERAL;
  // const menu = MENU_GENERAL;

  return (
    <ul className="main-menu-sections">
      {menu.map((nav, i) =>
        !nav.isMenu ? (
          <li key={`key-menu-${i}`} className="main-menu-dropdown">
            <a href={nav.path} className="main-menu-dropdown__button">
              {nav.title}
            </a>
          </li>
        ) : (
          <MainMenuDropdown key={`key-menu-${i}`} menu={nav} />
        )
      )}
    </ul>
  );
};

export default MainMenuSections;
