/**
 * Checks if all values in an object are null.
 *
 * This function takes an object and returns `true` if all its values 
 * are null; otherwise, it returns `false`.
 *
 * @param {Object} data - The object to check.
 * @returns {boolean} - `true` if all values are null, `false` otherwise.
 *
 * @example
 * checkEmptyData({ a: null, b: null }); // returns true
 * checkEmptyData({ a: null, b: 1 });    // returns false
 */
export const checkEmptyData = (data, lookup) => {
  if (!data) {
    return false
  }

  if (lookup !== "otherattributes") {
    const { id, __typename, ...rest } = data
    return Object.values(rest).every(value => value === null);
  }

  const { id, certificateDomain, medLogo, ukcaLogo, __typename, ...rest } = data
  return Object.values(rest).every(value => value === null || value === "" || value === false);

}
